html, body, #root, .App {
  height: 100%;
}

.App-header {
  background-color: white;
  height: 30%;
}

.App-link {
  color: #61dafb;
}
